



































import { Vue, Component } from "vue-property-decorator";
import DoctorReviewService from "../../service/DoctorReviewService";
import AuthUser from "../../../global-config/AuthUser";
@Component
export default class FavouriteList extends Vue {
  reviewUser: any[] = [];
  created() {
    DoctorReviewService.getDoctorReviewUserInfo(AuthUser.getId()).then((res) => {
      this.reviewUser = res.data;
    });
  }
}
